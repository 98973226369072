import React, { ReactNode } from "react"
import { CrmError } from "../../../../hooks/useCrmApi"
import * as styles from "./CrmFormError.module.scss"

export type CrmFormErrorProps = {
  message?: ReactNode
  error: CrmError
  onResetClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const CrmFormError: React.FC<CrmFormErrorProps> = props => {
  const { error } = props
  return (
    <>
      {props.message}
      <details className={styles.details}>
        <summary>Details</summary>
        <dl>
          <dt>Code</dt>
          <dd>{error.code}</dd>
          <dt>Message</dt>
          <dd>{error.message}</dd>
        </dl>
      </details>
      <p>
        Click{" "}
        <a href="#" onClick={props.onResetClick}>
          here
        </a>{" "}
        to try again
      </p>
    </>
  )
}

export default CrmFormError
