import { ApiResponseContact } from "functions/harvia-crm/types/api"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import useCrmApi, { AzureEndpoint, hasError } from "../../../hooks/useCrmApi"
import { DatoCrmFormNode } from "../../dato/blocks/DatoForm"
import * as styles from "./CrmForm.module.scss"
import CrmFormError from "./subcomponents/CrmFormError"

type CrmFormProps = {
  endpoint: AzureEndpoint
  form: DatoCrmFormNode
  title?: ReactNode
  /** Help text or like content from Dato */
  description?: ReactNode
  /** Content (from Dato) shown after successful submission */
  successMessage?: ReactNode
  /** Generic "Something went terribly wrong" message from Dato  */
  errorMessage?: ReactNode
  /** Do we try to excecute reCAPTCHA during form submission? */
  recaptcha?: boolean
}

type UiState = "reset" | "error" | "upload" | "success"

// TODO Contact form specific details
type ResponseData = ApiResponseContact

/**
 * Renders and manages forms that send data to Ha
 */
const CrmForm = (props: CrmFormProps) => {
  // console.debug("Rendering CrmForm", props)
  const [state, submit, reset, uploadFiles] = useCrmApi<ResponseData>(props.endpoint)
  const [uiState, setUiState] = useState<UiState>("reset")
  const containerRef = useRef<HTMLDivElement>(null) // so that we can scroll this into view after uiState change if needed
  const formRef = useRef<HTMLFormElement>(null)
  const [files, setFiles] = useState<File[]>([])
  const { executeRecaptcha } = useGoogleReCaptcha()
  const Form = props.form

  useEffect(() => {
    if (containerRef.current && (uiState === "error" || uiState === "success")) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    }
  }, [uiState])

  useEffect(() => {
    if (state.data) {
      // state.data !== undefined implies API request succeeded and we have the response data
      updateUI("success")
    } else if (state.error) {
      // Form send Error view
      console.warn("Form error", state.error)
      updateUI("error")
    }
  }, [state])

  const startUpload = async () => {
    if (!state.data || files.length < 1) {
      return
    }

    const { ticketId, token } = state.data
    const urlPath = `ticket/${ticketId}/file`

    const filesToUpload = [...files]
    setFiles([]) // reset

    const uploaded = await uploadFiles(urlPath, token, filesToUpload)

    // console.debug("Upload finsihed", uploaded)

    if (uploaded.find(u => u.success) === undefined) {
      return true
    }

    return false
  }

  const updateUI = async (newState: UiState): Promise<void> => {
    if (newState === "success" && state.data && files.length) {
      setUiState("upload")
      const success = await startUpload()
      setUiState(success ? "success" : "error")
    } else {
      setUiState(newState)
    }
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (formRef.current === null) {
      return
    }

    // should/could this be better handled in useCrmApi submit() function? After recaptcha? both?
    if (hasError(state.error)) {
      // console.debug("Skipping form submit because of errors", state.error)
      return
    }

    let captchaToken = ""
    if (executeRecaptcha) {
      try {
        captchaToken = await executeRecaptcha(props.endpoint.replaceAll("-", "_"))
      } catch (ex) {
        // this happens if GoogleReCaptchaProvider is missing (i.e. there was no recaptcha key set in Dato, for example)
        console.warn("Recaptcha execute threw an exception", ex)
      }
    }
    // console.debug("ReCAPTCHA token", token)

    const data = new FormData(formRef.current)
    data.append("captcha", captchaToken)
    // data.append("source", props.endpoint) // each form has its own endpoint for now so this would be redundant

    // console.debug("Submitting form", data, files)
    submit(data, files)
  }

  const onResetClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    reset()
    updateUI("reset")
  }

  // we use CSS to hide/show the form, error and success messages depending on this variable
  let errorView, successView

  if (uiState === "success") {
    // Form send Success view
    if (props.successMessage) {
      successView = <div className={styles.message}>{props.successMessage}</div>
    } else {
      successView = <p>Thank you</p>
    }
  }

  if (uiState === "error" && state.error) {
    // error message from backend API

    // props.errorMessage is generic "Something went terribly wrong" message from Dato or undefined
    errorView = (
      <CrmFormError
        error={state.error}
        message={props.errorMessage && <div className={styles.message}>{props.errorMessage}</div>}
        onResetClick={onResetClick}
      />
    )
  }

  return (
    <div className={styles[uiState]} ref={containerRef}>
      {props.title}
      <div className="description">{props.description}</div>
      <div className="success">{successView}</div>
      <div className={"form " + styles.form}>
        <Form
          formRef={formRef}
          onSubmit={onSubmit}
          onFilesChange={setFiles}
          loading={state.loading}
          errors={state.error?.fieldErrors}
        />
      </div>
      <div className="error">{errorView}</div>
    </div>
  )
}

export default CrmForm
